export default function PasswordField({ label, value, onChange, className, inputClass, labelClass, placeholder, onBlur, required }) {
    return (
        <div className={className}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
                {label} {required && <span className='text-red-500'>*</span>}
            </label>
            <div className='mt-2'>
                <input required={required} type='password' onBlur={onBlur} placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} className={`bg-slate-100 block w-full rounded border-0 px-3 p-2 text-neutral-700 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-1 focus:ring-gray-200 sm:text-sm sm:leading-6 ${inputClass}`} />
            </div>
        </div>
    )
}
